import React, { useEffect, useState } from 'react';

import Button from '@rotaryintl/harris-button';
import RadioField from '@rotaryintl/harris-form-basic/dist/RadioField/RadioField';
import TextField from '@rotaryintl/harris-form-basic/dist/TextField/TextField';
import Pagination from '@rotaryintl/harris-pagination';
import Table from '@rotaryintl/harris-table';
import classNames from 'classnames';
import { Form, Formik } from 'formik';

import Loading from '@components/Loading';
import AccessDenied from '@components/Pages/AccessDenied';
import NotFound from '@components/Routing/NotFound';
import { FilterClubType } from '@presenters/web/components/FilterClubType/FilterClubType';
import NoResultFound from '@presenters/web/components/NoResultsFound/NoResultFound';

import './styles.css';

import { CLUBS_TYPES_WITHOUT_SATELLITE } from '@domain/districts';

import { getDistrictIdFromPath } from '@use-cases/districts';
import { getDistrictFinancePath } from '@use-cases/districts/helpers/getDistrictFinancePath';

import {
  useFetchDistrictFinanceAccess,
  useSearchAllActiveClubsByDistrictId,
} from '@repositories/districts';

import { FEATURE_INVOICE, isEnabled } from '@utils/features';
import { getClubTypeShortName } from '@utils/getClubTypeShortName';
import { localizedNavigate } from '@utils/localized-navigate';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import { DIS } from '@typings/dis';
import { SearchAllActiveClubsInDistrictQuery } from '@typings/operations';

interface BackButtonProps {
  t: (key: string, defaultValue?: string) => string;
  onBackClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ t, onBackClick }) => (
  <Button
    clickHandler={onBackClick}
    icon="fas-angle-left"
    size="md"
    variant="text"
    className="mt-8"
  >
    {t('back-link-text', 'Back')}
  </Button>
);

export type Lang = 'en' | 'it' | 'de' | 'es' | 'fr' | 'pt' | 'ja' | 'ko';
interface Props {
  riDistrictId: number;
  languagePrefix: Lang;
}

const SEARCH_TYPES = {
  NAME: 'club name',
  ID: 'club id',
};

const PAGE_SIZE = 10;

const ViewInvoices: React.FC<Props> = ({ riDistrictId, languagePrefix }) => {
  const { t } = useTranslation();
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);
  const [
    searchAllActiveClubsInDistrictQuery,
    { data: invoiceData, loading },
  ] = useSearchAllActiveClubsByDistrictId();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchTermType, setSearchTermType] = useState<string>('');

  const [selectedClubType, setSelectedClubType] = useState<string[]>([]);
  const [hasDistrictData, setHasDistrictData] = useState<boolean>(true);

  const handleFilterChange = (selectedFilters: string[]) => {
    setSelectedClubType(selectedFilters);
    setCurrentPage(1);
  };

  const onPageChange = (event: React.SyntheticEvent, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const submitHandler = (values: { radioField: string; search: string }) => {
    setSearchTerm(values.search);
    setCurrentPage(1);

    setSearchTermType(values.radioField);
  };

  const [
    fetchDistrictFinanceAccess,
    { data: financeAccess },
  ] = useFetchDistrictFinanceAccess();

  useEffect(() => {
    if (riDistrictId) {
      fetchDistrictFinanceAccess({
        variables: { districtId: String(riDistrictId) },
      });
      searchAllActiveClubsInDistrictQuery({
        variables: {
          clubName: searchTermType === SEARCH_TYPES.NAME ? searchTerm : '',
          clubTypes: selectedClubType.length
            ? selectedClubType
            : CLUBS_TYPES_WITHOUT_SATELLITE,
          riClubId: searchTermType === SEARCH_TYPES.ID ? searchTerm : '',
          riDistrictId,
          status: [DIS.ClubStatus.Active, DIS.ClubStatus.Suspended],
          page: currentPage,
          pageSize: PAGE_SIZE,
        },
      });
    }
    if (riDistrictId === null) {
      setHasDistrictData(false);
    }
  }, [currentPage, searchTerm, searchTermType, riDistrictId, selectedClubType]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }

  const storedActiveTabDataDistrict = window.localStorage.getItem(
    'activeDistrictTabValue'
  );
  const activeTabDataDistrict = storedActiveTabDataDistrict
    ? JSON.parse(storedActiveTabDataDistrict)
    : [];
  const navigatedFromDistrictActiveTab = activeTabDataDistrict[0]?.value;

  const handleBackNavigation = () => {
    let redirectToDistrictFinancePath = '/my-districts';
    if (navigatedFromDistrictActiveTab) {
      const homeDistrictId = getDistrictIdFromPath(
        navigatedFromDistrictActiveTab
      );
      redirectToDistrictFinancePath = getDistrictFinancePath(homeDistrictId);
    }
    localizedNavigate(redirectToDistrictFinancePath);
  };

  if (
    (financeAccess && !financeAccess.districtFinanceOperationAccess) ||
    !hasDistrictData
  ) {
    return (
      <div>
        <BackButton t={t} onBackClick={handleBackNavigation} />
        <AccessDenied />
      </div>
    );
  }

  const renderLoadingOrNoResult = (
    loading: boolean,
    invoiceData: SearchAllActiveClubsInDistrictQuery | undefined,
    searchTerm: string
  ) => {
    if (loading) {
      return <Loading />;
    }

    if (
      !loading &&
      (!invoiceData || invoiceData.allActiveClubsInDistrict.clubs.length === 0)
    ) {
      return <NoResultFound searchParams={searchTerm} />;
    }

    return null;
  };

  const redirectLink = (
    clubId: string,
    docType: string,
    isCurrentInvoice: boolean
  ) => {
    return localizedNavigate(
      `/view-club-invoice-report/${clubId}?docType=${docType}&isCurrentInvoice=${isCurrentInvoice}`
    );
  };

  return (
    <>
      <Helmet
        titleTemplate={`${t(
          'metadata.title.district-invoice',
          'View Invoices'
        )}: %s | ${t('metadata.title.default', 'My Rotary')}`}
      />
      <div>
        <BackButton t={t} onBackClick={handleBackNavigation} />
        <h2 className="pt-6 font-semibold text-xl-38 mobile-m:text-xl-48 tablet:text-xl-58">
          {t('district-invoice.club-invoices', 'Club Invoices')}
        </h2>
        <div className="flex flex-col gap-12 tablet:gap-16">
          <Formik
            initialValues={{
              radioField: searchTermType || SEARCH_TYPES.NAME,
              search: searchTerm || '',
            }}
            onSubmit={submitHandler}
            css="my-8"
          >
            {({ values, submitForm, resetForm }) => (
              <Form>
                <RadioField
                  label={t(
                    'district-invoice.choose-club-search-type-radio-label',
                    'Choose club search type'
                  )}
                  name="radioField"
                  options={[
                    {
                      label: t(
                        'district-invoice.club-name-option',
                        'Club name'
                      ),
                      value: SEARCH_TYPES.NAME,
                    },
                    {
                      label: t('district-invoice.club-id-option', 'Club ID'),
                      value: SEARCH_TYPES.ID,
                    },
                  ]}
                />
                <TextField
                  label={
                    values.radioField === SEARCH_TYPES.NAME
                      ? t('district-invoice.club-name-option', 'Club name')
                      : t('district-invoice.club-id-option', 'Club ID')
                  }
                  name="search"
                  placeholder={`${t(
                    'district-invoice.search-by-placeholder',
                    'Search by'
                  )} ${
                    values.radioField === SEARCH_TYPES.NAME
                      ? t('district-invoice.club-name-option', 'Club name')
                      : t('district-invoice.club-id-option', 'Club ID')
                  }`}
                  icon="search"
                  size="md"
                  type="search"
                  className="leading-7 font-semibold placeholder:font-normal w-full tablet:w-4/12"
                />
                <div className="flex flex-row gap-4 pb-2">
                  <Button clickHandler={submitForm}>
                    {t('district-invoice.search', 'Search')}
                  </Button>
                  {values.search && (
                    <Button
                      clickHandler={() => {
                        resetForm();
                        setSearchTerm('');
                      }}
                      size="md"
                      variant="text"
                    >
                      {t('district-invoice.reset', 'Reset')}
                    </Button>
                  )}
                </div>
              </Form>
            )}
          </Formik>

          <div
            className={classNames('', {
              hidden: !(
                invoiceData?.allActiveClubsInDistrict?.totalCount ||
                loading ||
                0
              ),
              block: !!(
                invoiceData?.allActiveClubsInDistrict?.totalCount ||
                loading ||
                0
              ),
            })}
          >
            <FilterClubType
              onFilterChange={handleFilterChange}
              languagePrefix={
                languagePrefix
                  ? (languagePrefix.replace(/\/$/, '') as Lang)
                  : 'en'
              }
              resultCount={
                invoiceData?.allActiveClubsInDistrict?.totalCount || 0
              }
            />
          </div>
        </div>

        {!loading &&
        invoiceData &&
        invoiceData.allActiveClubsInDistrict.clubs.length > 0 ? (
          <>
            <div className="text-md text-naturals-gray-600 font-semibold py-4 tablet:py-6 border-b tablet:border-0 border-gray-500">
              {t('district-invoice.club-invoices', 'Club Invoices')} (
              {invoiceData?.allActiveClubsInDistrict?.totalCount ?? 0})
            </div>
            <Table
              id="DistrictInvoiceTable"
              bodyRows={invoiceData.allActiveClubsInDistrict.clubs.map(
                data => ({
                  cells: [
                    {
                      text: (
                        <span className="font-normal">
                          {`${data.name}, ${
                            data?.location?.state
                              ? `${data.location.state}, `
                              : ''
                          }${data?.location?.country || ''}`}
                        </span>
                      ),
                    },
                    {
                      text: <span>{getClubTypeShortName(t, data.type)}</span>,
                    },
                    {
                      text: <span>{data.riClubId}</span>,
                    },
                    {
                      text: (
                        <span className="text-dark-blue-500 flex flex-col">
                          <span
                            className="font-semibold hover:underline hover:cursor-pointer"
                            onClick={() => redirectLink(data.id, 'I', true)}
                          >
                            {t(
                              'district-invoice.current-invoice',
                              'Current invoice'
                            )}
                          </span>
                          <span
                            className="font-semibold hover:underline hover:cursor-pointer pt-2"
                            onClick={() => redirectLink(data.id, 'D', true)}
                          >
                            {t(
                              'district-invoice.current-invoice-details',
                              'Current invoice details'
                            )}
                          </span>
                        </span>
                      ),
                    },
                    {
                      text: (
                        <div className="text-dark-blue-500">
                          <div
                            className="font-semibold hover:underline hover:cursor-pointer"
                            onClick={() => redirectLink(data.id, 'I', false)}
                          >
                            {t(
                              'district-invoice.previous-invoice',
                              'Previous invoice'
                            )}
                          </div>
                          <div
                            className="font-semibold hover:underline hover:cursor-pointer pt-2"
                            onClick={() => redirectLink(data.id, 'D', false)}
                          >
                            {t(
                              'district-invoice.previous-invoice-details',
                              'Previous invoice details'
                            )}
                          </div>
                        </div>
                      ),
                    },
                  ],
                })
              )}
              bodyStyle="default"
              headerStyle="light"
              displayHeader
              headerRow={{
                cells: [
                  {
                    id: 'club-name',
                    text: (
                      <div className="cell-label">
                        {t('district-invoice.club-name', 'Club Name')}
                      </div>
                    ),
                  },
                  {
                    id: 'club-type',
                    text: (
                      <div className="cell-label">
                        {t('district-invoice.club-type', 'Club Type')}
                      </div>
                    ),
                  },
                  {
                    id: 'club-id',
                    text: (
                      <div className="cell-label">
                        {t('district-invoice.id', 'ID')}
                      </div>
                    ),
                  },
                  {
                    id: 'club-current-invoice',
                    text: (
                      <div className="flex-col items-baseline hidden tablet:flex">
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'district-invoice.current-invoice',
                            'Current invoice'
                          )}
                        </div>
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'district-invoice.current-invoice-details',
                            'Current invoice details'
                          )}
                        </div>
                      </div>
                    ),
                  },
                  {
                    id: 'club-previous-invoice',
                    text: (
                      <div className="hidden tablet:flex flex-col items-baseline">
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'district-invoice.previous-invoice',
                            'Previous invoice'
                          )}
                        </div>
                        <div className="m-0 p-0 ml-1">
                          {t(
                            'district-invoice.previous-invoice-details',
                            'Previous invoice details'
                          )}
                        </div>
                      </div>
                    ),
                  },
                ],
              }}
            />
            <div className="py-6">
              <Pagination
                currentPage={currentPage}
                lang={
                  languagePrefix
                    ? (languagePrefix.replace(/\/$/, '') as Lang)
                    : 'en'
                }
                onPageChange={onPageChange}
                pageSize={PAGE_SIZE}
                siblingCount={1}
                totalCount={
                  invoiceData?.allActiveClubsInDistrict?.totalCount || 0
                }
                variant="Dropdown"
              />
            </div>
          </>
        ) : (
          renderLoadingOrNoResult(loading, invoiceData, searchTerm)
        )}
      </div>
    </>
  );
};

export default ViewInvoices;
