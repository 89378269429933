import * as React from 'react';
import { useEffect, useRef } from 'react';

import IconFont from '@rotaryintl/harris-icon-font';

import { useTranslation } from '@external/react-i18next';

export interface NoResultFoundProps {
  searchParams: string;
}

const NoResultFound: React.FC<NoResultFoundProps> = ({ searchParams }) => {
  const { t } = useTranslation();
  const targetRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const offset = 350;
    if (targetRef.current) {
      const topPosition =
        targetRef.current.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center text-naturals-gray-600 desktop:my-16 gap-2 border-t-2 border-solid border-gray-275 pt-6">
      <div ref={targetRef} />
      <IconFont
        color="#323444"
        size="24"
        type="fas-magnifying"
        className="p-4 rounded-full bg-gray-100 mb-2"
      />

      <h5 className="font-semibold leading-lg-heading text-2md m-0">
        {t('no-results-found', 'No results found')}
      </h5>
      <p className="p-0 m-0">
        {t(
          'no-results-found-description',
          'No results were found for your search term "{{searchParams}}". Please try again',
          { searchParams }
        )}
      </p>
    </div>
  );
};
export default NoResultFound;
