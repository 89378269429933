import React, { useEffect, useRef, useState } from 'react';

import Button from '@rotaryintl/harris-button';

import AccessDenied from '@components/Pages/AccessDenied';
import NotFound from '@components/Routing/NotFound';

import { getDistrictIdFromPath } from '@use-cases/districts';
import { getDistrictFinancePath } from '@use-cases/districts/helpers/getDistrictFinancePath';
import { useNotifications } from '@use-cases/notifications';

import { useFetchDistrictFinanceAccess } from '@repositories/districts';

import { FEATURE_INVOICE, isEnabled } from '@utils/features';
import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

interface BackButtonProps {
  t: (key: string, defaultValue?: string) => string;
  onBackClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ t, onBackClick }) => (
  <Button
    clickHandler={onBackClick}
    icon="fas-angle-left"
    size="md"
    variant="text"
    className="mt-8"
  >
    {t('back-link-text', 'Back')}
  </Button>
);

interface Props {
  riDistrictId: number;
}

const ViewClubBalanceReport: React.FC<Props> = ({ riDistrictId }) => {
  const { t } = useTranslation();
  const [
    fetchDistrictFinanceAccess,
    { data: financeAccess },
  ] = useFetchDistrictFinanceAccess();
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);
  const { addError } = useNotifications();
  const addErrorRef = useRef(addError);
  const [hasDistrictData, setHasDistrictData] = useState<boolean>(true);

  useEffect(() => {
    const channel = new BroadcastChannel('district-balance-reports');

    addErrorRef.current = addError;

    const handleMessage = (event: MessageEvent) => {
      if (event?.data?.type === 'error') {
        const errorMessage = t(
          'club-invoice.error-message',
          'We could not download the report, Please try again later'
        );
        addErrorRef.current(errorMessage, { id: 'district-balance-reports' });
      }
    };

    channel.addEventListener('message', handleMessage);

    return () => {
      channel.removeEventListener('message', handleMessage);
      channel.close();
    };
  }, [t, addError]);

  useEffect(() => {
    if (riDistrictId) {
      fetchDistrictFinanceAccess({
        variables: { districtId: String(riDistrictId) },
      });
    }
    if (riDistrictId === null) {
      setHasDistrictData(false);
    }
  }, [riDistrictId]);

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }

  const storedActiveTabDataDistrict = window.localStorage.getItem(
    'activeDistrictTabValue'
  );
  const activeTabDataDistrict = storedActiveTabDataDistrict
    ? JSON.parse(storedActiveTabDataDistrict)
    : [];
  const navigatedFromDistrictActiveTab = activeTabDataDistrict[0]?.value;

  const handleBackNavigation = () => {
    let redirectToDistrictFinancePath = '/my-districts';
    if (navigatedFromDistrictActiveTab) {
      const homeDistrictId = getDistrictIdFromPath(
        navigatedFromDistrictActiveTab
      );
      redirectToDistrictFinancePath = getDistrictFinancePath(homeDistrictId);
    }
    localizedNavigate(redirectToDistrictFinancePath);
  };

  if (
    (financeAccess && !financeAccess.districtFinanceOperationAccess) ||
    !hasDistrictData
  ) {
    return (
      <div>
        <BackButton t={t} onBackClick={handleBackNavigation} />
        <AccessDenied />
      </div>
    );
  }

  return (
    <div>
      <BackButton t={t} onBackClick={handleBackNavigation} />
      <h1 className="font-semibold text-xl-38 mobile-m:text-xl-48 tablet:text-xl-58 tablet:p-0 m-0 my-6 tablet:mb-8 tablet:leading-xl-58">
        {t(
          'district-invoice.daily-club-balance-report.title',
          'Daily Club Balance Report'
        )}
      </h1>
      <div id="DailyClubBalanceDownloadReport">
        <span>
          {t(
            'district-invoice.daily-club-balance-report-download-msg',
            'We are downloading daily club balance report for you and opening it in new tab.'
          )}
        </span>
      </div>
    </div>
  );
};

export default ViewClubBalanceReport;
