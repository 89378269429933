import React from 'react';

import LinksBox from '@components/LinksBox';
import { titleTemplateParams } from '@components/Title/util';

import { RIBI_COUNTRY_LIST } from '@domain/common';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import { Drupal } from '@typings/drupal';
import { FetchDistrictDetailsQuery } from '@typings/operations';

interface Props {
  financeData: Drupal.MenuItem[];
  districtData: FetchDistrictDetailsQuery;
}
const hasExactMatch = (arr: string[], str: string): boolean =>
  arr.some(word => new RegExp(`\\b${word}\\b`, 'i').test(str));

const DistrictFinance: React.FC<Props> = ({ financeData, districtData }) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);

  const { districtLegalName } = districtData?.districtDetails || {};
  const isRibiDistrict = hasExactMatch(
    RIBI_COUNTRY_LIST,
    districtLegalName || ''
  );
  const displayedFinanceData =
    isRibiDistrict && financeData.length > 1
      ? financeData.slice(2, 3)
      : financeData;

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-finance',
          '{{prefix}} District finance {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="mt-12">
        <LinksBox
          header={t('header.main-menu.districts-menu-label', 'Districts')}
          items={displayedFinanceData}
        />
      </div>
    </>
  );
};

export default DistrictFinance;
