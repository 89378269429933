import React, { useCallback, useEffect, useState } from 'react';

import Button from '@rotaryintl/harris-button/dist/button.component';
import Chip from '@rotaryintl/harris-chip';
import {
  FilterCategoryAccordion,
  FilterCategoryDisclosure,
} from '@rotaryintl/harris-filtering';
import BaseModal from '@rotaryintl/harris-modal';
import classNames from 'classnames';
import { Form, Formik, FormikValues, useFormikContext } from 'formik';

import { Lang } from '@presenters/web/pages/Districts/ViewInvoices/ViewInvoices';

import { useTranslation } from '@external/react-i18next';
import { ScreenSizes, useScreenSizeLayout } from '@hooks/useScreenSizeLayout';

import { DIS } from '@typings/dis';

interface ChipDisplayProps {
  onFilterChange: (selectedFilters: string[]) => void;
  filterClubTypeOption: { checked: boolean; label: string; value: string }[];
}

const ChipDisplayComponent: React.FC<ChipDisplayProps> = ({
  filterClubTypeOption,
  onFilterChange,
}) => {
  const { values, setValues } = useFormikContext<FormikValues>();

  useEffect(() => {
    onFilterChange(values.clubType);
  }, [values.clubType]);

  const removeClubTypeOptionChip = useCallback(
    (val: string) => {
      setValues((prev: { clubType: string[] }) => ({
        ...prev,
        clubType: prev.clubType.filter(option => option !== val),
      }));
    },
    [setValues]
  );
  const selectedClubTypes = new Set(values.clubType);
  const matchingClubTypes = filterClubTypeOption.filter(option =>
    selectedClubTypes.has(option.value)
  );
  return (
    <div
      className={`flex flex-row gap-1 ${
        matchingClubTypes.length ? 'tablet:pt-6 pt-4' : ''
      }`}
    >
      {matchingClubTypes.map(({ value, label }) => (
        <Chip
          key={value}
          label={label}
          onClick={() => removeClubTypeOptionChip(value)}
        />
      ))}
    </div>
  );
};

interface FilterClubTypeProps {
  onFilterChange: (selectedFilters: string[]) => void;
  languagePrefix: Lang;
  resultCount: number;
}

export const FilterClubType: React.FC<FilterClubTypeProps> = ({
  onFilterChange,
  languagePrefix,
  resultCount,
}) => {
  const { t } = useTranslation();
  const isMobileScreenSize = useScreenSizeLayout(ScreenSizes.mobileM);
  const [filterClubTypeOption] = useState([
    { checked: false, label: 'Rotary', value: DIS.ClubTypeEnum.RotaryClub },
    { checked: false, label: 'Rotaract', value: DIS.ClubTypeEnum.RotaractClub },
  ]);
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <div className="block tablet:hidden club-type-filter-modal">
        <Button
          clickHandler={() => setOpenModal(true)}
          variant="tertiary"
          icon="fas-sliders"
        >
          <span>{t('district-invoice.filters', 'Filters')}</span>
        </Button>
        <div className="">
          <Formik
            initialValues={{
              clubType: [],
            }}
            validateOnChange
            onSubmit={() => {}}
          >
            {({ setValues, values }) => {
              return (
                <Form>
                  {values.clubType.length > 0 && (
                    <div className="mobile-m:flex flex-row pt-4 hidden">
                      <div className="font-bold text-naturals-gray-600">
                        {t('district-invoice.filters', 'Filters')}
                      </div>
                      <div
                        className="ml-auto font-bold text-dblue cursor-pointer"
                        onClick={() => setValues({ clubType: [] })}
                      >
                        {t('district-invoice.clear-all', 'Clear all')}
                      </div>
                    </div>
                  )}
                  <div
                    className={classNames('', {
                      block: openModal,
                      hidden: !openModal,
                    })}
                  >
                    <BaseModal
                      isOpen={openModal}
                      isFullscreen={isMobileScreenSize}
                      body={
                        <>
                          <div className="flex flex-row pb-4 mobile-m:hidden">
                            <div className="font-bold text-naturals-gray-600">
                              {t('district-invoice.filters', 'Filters')}
                            </div>
                            {values.clubType.length > 0 && (
                              <div
                                className="ml-auto font-bold text-dblue cursor-pointer"
                                onClick={() => setValues({ clubType: [] })}
                              >
                                {t('district-invoice.clear-all', 'Clear all')}
                              </div>
                            )}
                          </div>

                          <div className="flex flex-row gap-4 tablet:gap-6">
                            <div className="w-full" data-testid="mobile-modal">
                              <FilterCategoryAccordion
                                key={filterClubTypeOption
                                  .map(opt => opt.checked)
                                  .join(',')}
                                accordianCardProps={{
                                  buttonIcon: 'fas-angle-down',
                                  headlineText: t(
                                    'district-invoice.club-type',
                                    'Club type'
                                  ),
                                  items: [],
                                }}
                                filterCategoryChecklistProps={{
                                  className:
                                    'rotaryui-filter-category-checklist-card',
                                  lang: languagePrefix,
                                  name: 'clubType',
                                  options: filterClubTypeOption,
                                  title: t(
                                    'district-invoice.club-type',
                                    'Club type'
                                  ),
                                }}
                              />
                            </div>
                          </div>
                        </>
                      }
                      closeModal={() => {
                        setOpenModal(false);
                      }}
                      primaryButton={{
                        children: `${t(
                          'district-invoice-view-results',
                          `View results`
                        )} (${resultCount})`,
                        clickHandler: () => {
                          setOpenModal(false);
                        },
                      }}
                      title={t('district-invoice.filters', 'Filters')}
                    />
                  </div>
                  <ChipDisplayComponent
                    filterClubTypeOption={filterClubTypeOption}
                    onFilterChange={onFilterChange}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="hidden tablet:block">
        <Formik
          initialValues={{
            clubType: [],
          }}
          validateOnChange
          onSubmit={() => {}}
        >
          {({ setValues }) => {
            return (
              <Form>
                <div className="flex flex-row gap-6">
                  <div className="font-bold">
                    {t('district-invoice.filters', 'Filters')}:
                  </div>
                  <div className="w-74">
                    <FilterCategoryDisclosure
                      key={filterClubTypeOption
                        .map(opt => opt.checked)
                        .join(',')}
                      filterCategoryChecklistProps={{
                        lang: languagePrefix,
                        name: 'clubType',
                        options: filterClubTypeOption,
                        showFilter: true,
                        showTitle: true,
                        title: t('district-invoice.club-type', 'Club type'),
                      }}
                    />
                  </div>
                  <div
                    className="ml-auto font-bold text-dblue cursor-pointer"
                    onClick={() => setValues({ clubType: [] })}
                  >
                    {t('district-invoice.clear-all', 'Clear all')}
                  </div>
                </div>
                <ChipDisplayComponent
                  filterClubTypeOption={filterClubTypeOption}
                  onFilterChange={onFilterChange}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};
