import { TFunction } from 'i18next';

import { ClubType, Maybe } from '@typings/graphql';

export const getClubTypeShortName = (
  t: TFunction,
  type: Maybe<string> | undefined
): string => {
  switch (type) {
    case ClubType.RotaryClub:
      return t('club-type-short.rotary', 'Rotary');
    case ClubType.RotaractClub:
      return t('club-type-short.rotaract', 'Rotaract');
    case ClubType.SatelliteClub:
      return t('club-type-short.satellite', 'Rotary Satellite');
    case ClubType.RotaractSatelliteClub:
      return t('club-type-short.rotaract-satellite', 'Rotaract Satellite');
    default:
      return '';
  }
};
